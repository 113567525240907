<template>
	<div class="dredge-card">
		<div class="dredge-card-top">
			<h3>会员卡开通</h3>
      <div class="card-list">
        <div :class="[nowIdx == idx ? 'item-active':'' ,'card-item']" v-for="(item, idx) in vip_arr" :key="idx" @click="setType(item, idx)">
          <span>{{ item.name }}</span>
          <div class="card-price">
            <span>¥</span>
            <span>{{ item.money }}</span>
          </div>
        </div>
      </div>
		</div>

		<div class="dredge-card-main">
			<Panel title="个人信息">
				<CellGroup v-show="card_con.name_status == 1">
					<Field v-model="user_name" type="text" label="姓名"></Field>
				</CellGroup>
				<CellGroup>
					<Field
						v-show="card_con.mobile_status == 1"
						v-model="tel"
						type="tel"
						label="手机号"
					></Field>
					<Field
						v-model="birthday"
						v-show="card_con.brith_status == 1"
						disabled
						type="birthday"
						label="生日"
						@click="show_time = true"
					></Field>
				</CellGroup>
				<RadioGroup v-model="sex" v-show="card_con.sex_status == 1">
					<div class="sex-con">
						<span>性别:</span>
						<div>
							<Radio checked-color="rgba(101, 163, 239, 1)" name="1">男</Radio>
							<Radio checked-color="rgba(101, 163, 239, 1)" name="2">女</Radio>
						</div>
					</div>
				</RadioGroup>
				<!-- <Field
					v-show="card_con.vertify_status == 1"
					v-model="sms"
					center
					clearable
					label="验证码"
					placeholder="请输入短信验证码"
				>
					<Button
						slot="button"
						size="small"
						@click="getCode"
						:disabled="btn_status"
						type="primary"
						>{{ btn_status ? count + "s" : btnText }}</Button
					>
				</Field> -->
			</Panel>
		</div>
    <div class="dredge-card-main">
      <RadioGroup v-model="pay_type" style="margin-top:10px">
        <CellGroup>
          <Cell title="微信支付" icon="wechat" clickable data-name="1" @click="pay_type = '1'">
            <Radio slot="right-icon" name="1" checked-color="rgba(101, 163, 239, 1)"/>
          </Cell>
          <Cell title="余额支付" icon="gold-coin-o" clickable data-name="2" @click="pay_type = '2'">
            <Radio slot="right-icon" name="2" checked-color="rgba(101, 163, 239, 1)"/>
          </Cell>
        </CellGroup>
      </RadioGroup>
    </div>

		<ActionSheet v-model="show_time" title="选择日期">
			<DatetimePicker
				type="date"
				:formatter="formatter"
				:min-date="minDate"
				:max-date="maxDate"
				@confirm="make_time"
				@cancel="show_time = false"
			>
			</DatetimePicker>
		</ActionSheet>
		<div class="dredge-btn">
			<VanButton
				block
				@click="make_card()"
				color="rgba(69, 148, 254, 1)"
			>
				开通会员卡
			</VanButton>
		</div>
    <PayMoney
			v-if="payModel"
			ref="pay"
			:paytype="5"
			:money="set_nowObj.money"
		></PayMoney>
	</div>
</template>
<script>
import UserInfo from "@/api/user";
import Setting from "@/api/setting";
import CreditShop from "@/api/creditshop";
import { parseTime } from "@/utils/timeformat";
import { checkMobile } from "@/utils/validate";
import { goWxConfigJsApi, wxPayTwo } from "@/utils/wxpay";
import PayMoney from "@/components/public/PayMoney";
import {
	Button,
	Panel,
	CellGroup,
	Field,
	Radio,
  Cell,
	RadioGroup,
	ActionSheet,
	DatetimePicker
} from "vant";
export default {
	components: {
		VanButton: Button,
		Panel,
		CellGroup,
		Field,
		Radio,
		// Button,
    Cell,
    PayMoney,
		RadioGroup,
		ActionSheet,
		DatetimePicker
	},
	data() {
		return {
			user_name: "",
			tel: "",
			active_status: "1",
			birthday: "",
			vip_arr: [],
			now_type: "", //会员卡类型,
			show_time: false,
			minDate: new Date(1970, 0, 1),
			maxDate: new Date(),
			currentDate: new Date(),
			now_birthday: "",
			card_con: {},
			sms: "",
      sex: "1",
      payModel: false,
			timer: null,
			btnText: "获取验证码",
			btn_status: false,
      pay_type: "1",
			nowIdx: "-1",
			errmsg: "",
			myInfo: {},
			vip_status: false,
      set_nowObj: {
        is_pay: 0
      } // 当前会员卡内容
		};
  },
  watch: {
    pay_type: function(value) {
      if (value == 1) {
        return;
      } else if (value == 2) {
        this.payModel = true;
        this.isPassword();
      }
		},
		tel: function(val) {
			this.errmsg = checkMobile(val);
		}
  },
	mounted() {
		this.$nextTick(() => {
      goWxConfigJsApi(); // 获取微信配置
			this.getVipTypeList();
			this.getSetDetail();
			this.getUserInfo();
			this.isVip();
		});
	},
	methods: {
    setType(item, idx) {
      this.set_nowObj = item, 
      this.now_type = item.id
      this.nowIdx = idx;
		},
		async isVip() {
      try {
        const res = await UserInfo.isVip();
        if (res.code == 200) {
					this.vip_status = Boolean(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
		async getUserInfo() {
      try {
        const res = await Setting.GetInfo();
        console.log("获取用户信息", res);
        if (res.code == 200) {
					this.myInfo = res.data;
					this.user_name = res.data.username;
					this.tel = res.data.mobile;
        } else if (res.code == 0) {
          if (this.isWeixin_status) {
            this.$router.push("/bindPhone");
          } else {
            this.$dialog
              .confirm({
                title: res.msg
              })
              .then(() => {
                this.$router.push("/userlogin");
              })
              .catch(() => {});
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
		getCode() {
			const TIME_COUNT = 60;
			if (!this.timer) {
				this.count = TIME_COUNT;
				this.timer = setInterval(() => {
					if (this.count > 0 && this.count <= TIME_COUNT) {
						this.count--;
						this.btn_status = true;
						console.log(this.count);
					} else {
						this.btn_status = false;
						clearInterval(this.timer);
						this.timer = null;
					}
				}, 1000);
			}
    },
    // 打开余额支付
		showBalanceToast() {
			this.$nextTick(() => {
				this.$refs.pay.showPayToast();
			}, 100);
		},
    // 判断是否设置交易密码
		async isPassword() {
			try {
				const res = await Setting.IfVerify("pay_password");
				if (res.code == 200) {
          this.showBalanceToast();
					return true;
				} else {
					this.$dialog
						.confirm({
							title: res.msg,
							message: "您暂未设置交易密码，是否前往设置?"
						})
						.then(() => {
              // 设置交易密码判断类型
              sessionStorage.setItem('verifyType', "dealPassword");
							this.$router.push("/userSafety");
						})
						.catch(() => {
							throw res.msg;
						});
				}
			} catch (error) {
				this.$toast(error);
			}
		},
		formatter(type, value) {
			if (type === "year") {
				return `${value}年`;
			} else if (type === "month") {
				return `${parseInt(value)}月`;
			} else if (type === "day") {
				return `${parseInt(value)}日`;
			}
			return value;
		},
		// 会员卡类型
		async getVipTypeList() {
			try {
				const res = await CreditShop.vip_cardlist();
				if (res.code == 200) {
					if (res.data.data.length > 0) {
						this.vip_arr = res.data.data;
					} else {
						throw res.msg;
					}
				}
			} catch (error) {
				this.$toast(error);
			}
		},
		// 会员卡填写内容
		async getSetDetail() {
			try {
				const res = await CreditShop.vip_set_detail();
				if (res.code == 200) {
					this.card_con = res.data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		make_time(value) {
			// 生日
			this.show_time = false;
			this.birthday = parseTime(value);
		},
		async make_card(pwd = "") {
			try {
				if (this.user_name == "") throw("请输入用户名");
				if (this.tel == "") throw("请输入电话号码");
				const res = await CreditShop.openVipCard(
          this.now_type,
          this.user_name,
          this.tel,
          this.sex,
          this.birthday,
          pwd,
					this.pay_type == 1 ? "wxpay":"balance",
					this.vip_status ? 1:0
				);
				if (res.code == 200) {
          // 判断是否进行支付
          if (this.set_nowObj.is_pay == 0) {
            this.$router.push('/vip');
            return;
          } else {
            if (this.pay_type == 1) {
              // 微信支付
              wxPayTwo(res.data);
            } else {
							this.payModel = false;
							this.$toast(res.msg);
              setTimeout(() => {
                this.$router.push("/vip");
              }, 500);
            }
          }
				} else {
					throw res.msg;
				}
			} catch (error) {
				this.$toast(error);
			}
		}
	},
	beforeDestroy() {
		clearInterval(this.timer);
	}
};
</script>
<style lang="scss" scoped>
.dredge-card {
	width: 100%;
	height: 100vh;
	padding-top: 10px;
	background: rgba(244, 244, 244, 1);
	.dredge-btn,
	.dredge-card-top,
	.dredge-card-main {
		width: 96%;
		margin: 10px auto 0;
		border-radius: 6px;
		background: #ffffff;
		.sex-con {
			display: flex;
			height: 30px;
			padding: 0 18px;
			align-items: center;
			justify-content: space-between;
      span {
        font-size: 14px;
        color: #323233;
      }
			div {
				display: flex;
				margin-left: 10px;
			}
		}
	}
	.dredge-card-top {
		height: auto;
		font-size: 14px;
		padding: 20px 4px 10px;
		h3 {
			font-size: 14px;
			text-align: left;
			height: 30px;
      line-height: 30px;
      padding-left: 10px;
      color: rgba(51, 51, 51, 1);
		}
		.card-list {
			width: 100%;
			height: auto;
			display: flex;
			padding: 4px 8px;
			align-items: center;
			flex-wrap: wrap;
			margin-left: -10px;
			justify-content: flex-start;
			.card-item, .item-active {
				width: 30%;
				height: 58px;
				display: flex;
        flex-direction: column;
				margin-top: 10px;
				border-radius: 6px;
        padding: 12px 0;
        align-items: center;
				margin-left: 10px;
				border: 1px solid #dfdfdf;
				justify-content: space-between;
				.card-price {
					font-family: "Times New Roman", Times, serif;
          margin-top: 4px;
          color: rgba(51, 51, 51, 1);
				}
			}
      .item-active {
        color: rgba(69, 148, 254, 1) !important;
        border:1px solid rgba(0,149,255,1);
        background:rgba(69,148,254,0.1);
        .card-price {
          color: rgba(69, 148, 254, 1);
        }
      }
		}
	}
	.dredge-btn {
		margin-top: 50px;
	}
}
</style>
